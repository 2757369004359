<template>
  <v-app class="app-padding">
    <vue-topprogress color="#FCC03C" ref="topProgress"></vue-topprogress>
    <v-main class="main-padding">
      {{ isTutorial }}
      <dialog-tutorial v-if="isTutorial"></dialog-tutorial>
      <v-dialog v-model="dialogMajorUpdate" max-width="900" persistent>
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="4" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat persistent>
                  <v-card-title>
                    <span class="text-h5">Mise à jour majeure</span>
                  </v-card-title>

                  <v-card-text>
                    Nous tenons à vous informer qu'une mise à jour majeure est actuellement en cours de déploiement sur
                    Mes Services Assurance. <br />
                    Nous sommes conscients que cette situation peut causer des désagréments et nous tenons à exprimer
                    nos plus sincères excuses pour la gêne occasionnée.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <i class="fa-solid fa-triangle-exclamation EoleErrorBase--text fa-beat fa-5x"></i>
                  </v-card-text>
                  <v-card-text>
                    Durant cette période, l'application Mes Services Assurance ne sera pas accessible. <br />
                    Notre équipe technique travaille d'arrache-pied pour effectuer cette mise à jour le plus rapidement
                    possible et s'assurer que vous puissiez retrouver l'accès à vos services dans les plus brefs délais.
                  </v-card-text>

                  <v-spacer></v-spacer>
                  <v-card-action class="text-center mb-5">
                    <v-btn color="EoleError" text href="https://status.anavel.bzh/status/anavel" target="_blank">
                      Consulter l'état des services
                    </v-btn>
                  </v-card-action>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAlert" width="600px">
        <v-card>
          <v-card-title>Ajouter une nouvelle alerte</v-card-title>
          <v-card-text> Alertes en cours : </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPiloting" max-width="1400">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title>
                    <span class="text-h5">Tableau de pilotage Mes Services Assurance</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs
                      color="EoleYellow"
                      class="text-left mt-5"
                      v-model="tabsPiloting"
                      style="box-shadow: none !important"
                      background-color="transparent"
                      :show-arrows="false"
                    >
                      <v-tab href="#tab-1">Général</v-tab>
                      <v-tab href="#tab-2">Gestion des alertes</v-tab>
                      <v-tab href="#tab-3">Gestions des agences inscrites</v-tab>
                      <v-tab href="#tab-4">Stockage de données</v-tab>
                    </v-tabs>
                  </v-card-text>
                  <v-divider class="mt-n4"></v-divider>
                  <v-card-text>
                    <v-tabs-items v-model="tabsPiloting" style="background-color: transparent !important">
                      <v-tab-item :value="'tab-1'" class="mb-5">
                        <v-card-text>
                          <!-- <v-progress-linear
                            :value="diskStorage.percent"
                            :indeterminate="dataStorageLoading"
                          ></v-progress-linear> -->
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-card outlined elevation="0">
                                <v-card-title>Espace disque sur le serveur</v-card-title>
                                <v-card-text>
                                  <v-progress-linear
                                    :value="diskStorage.percent"
                                    :indeterminate="dataStorageLoading"
                                    :color="colorPercentage(diskStorage.percent)"
                                  ></v-progress-linear>
                                </v-card-text>
                                <v-card-text>
                                  Espaces disques utilisés : {{ diskStorage.used }} Go / {{ diskStorage.total }} Go
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-card outlined elevation="0">
                                <v-card-title>Jetons pappers restant</v-card-title>
                                <v-card-text>
                                  <v-progress-linear
                                    :value="pappers.percent"
                                    :color="inverseColorPercentage(pappers.percent)"
                                  ></v-progress-linear>
                                </v-card-text>
                                <v-card-text>
                                  Jetons restants : {{ pappers.tokensRemaining }} / {{ pappers.tokensSubscribed }}
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item :value="'tab-2'" class="mb-5">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-textarea
                                    outlined
                                    dense
                                    v-model="newAlert.message"
                                    label="Nouveau message d'alerte"
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="12" class="mt-n5">
                                  <v-select
                                    :items="[
                                      {
                                        text: 'Erreur',
                                        value: 'error',
                                      },
                                      {
                                        text: 'Succès',
                                        value: 'success',
                                      },
                                      {
                                        text: 'Information',
                                        value: 'info',
                                      },
                                      {
                                        text: 'Avertissement',
                                        value: 'warning',
                                      },
                                    ]"
                                    v-model="newAlert.state"
                                    label="Type d'alerte"
                                    dense
                                    outlined
                                  ></v-select>
                                </v-col>
                                <v-col class="mt-n10">
                                  <v-switch inset label="Mise à jour majeure" v-model="newAlert.majorUpdate">
                                  </v-switch>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-list>
                                <v-list-item v-for="alert in alerts" v-bind:key="alert.id" dense>
                                  <v-list-item-content>
                                    <v-alert text prominent dense border="top" :type="alert.state">
                                      <strong>{{ alert.datetime_display }}</strong> {{ alert.message }}
                                    </v-alert>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn color="EoleError" icon @click="deleteAlert(alert.id)">
                                      <i class="fa-solid fa-trash EoleError--text"></i>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="primary" text @click="dialogAlert = false">Annuler</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="postAlert">Ajouter</v-btn>
                        </v-card-actions>
                      </v-tab-item>
                      <v-tab-item :value="'tab-3'" class="mb-5">
                        <v-list style="z-index: 0" dense>
                          <template v-for="(agency, index) in agencies">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col cols="12" md="6" class="mt-6">
                                      <span class="font-weight-bold">{{ agency.name }}</span> ({{ agency.siret }}) -
                                      {{ agency.street }}, {{ agency.zip }} {{ agency.city }}
                                    </v-col>
                                    <v-col cols="12" md="6" class="text-right mt-4"> </v-col>
                                  </v-row>
                                </v-list-item-title>

                                <v-list-item-subtitle class="mt-n5">
                                  <v-row>
                                    <v-col cols="12" md="12" class="mt-6">
                                      <v-icon color="EoleBlue" size="16" left> fa-users </v-icon>

                                      <span class="font-weight-bold">Collaborateurs actifs</span> :
                                      {{ agency.statistics.activeCollaborators }}
                                    </v-col>
                                    <v-col cols="12" md="12" class="mt-n5">
                                      <v-icon color="EoleBlue" size="16" left> fa-users-slash </v-icon>
                                      <span class="font-weight-bold">Collaborateurs inactifs</span> :
                                      {{ agency.statistics.inactiveCollaborators }}
                                    </v-col>
                                    <v-col cols="12" md="12" class="mt-n5">
                                      <v-icon color="EoleYellow" size="16" left> fa-phone-volume </v-icon>
                                      <span class="font-weight-bold">Demande de rappels réalisées</span> :
                                      {{ agency.statistics.totalCalls }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text>
                                  <!-- <v-btn @click="openDraft(draft)" fab icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                                  <v-row>
                                    <v-col cols="12" md="12"> id: {{ agency.id }} </v-col>
                                    <v-col v-if="!!agency.pack" cols="12" md="12">
                                      {{ agency.pack.name }}
                                    </v-col>
                                  </v-row>
                                </v-list-item-action-text>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider v-if="index < agencies.length - 1"></v-divider>
                          </template>
                        </v-list>
                      </v-tab-item>
                      <v-tab-item :value="'tab-4'" class="mb-5">
                        <v-card-text>
                          Espaces disques utilisés : {{ diskStorage.used }} Go / {{ diskStorage.total }} Go
                        </v-card-text>
                        <v-card-text>
                          <v-progress-linear
                            :value="diskStorage.percent"
                            :indeterminate="dataStorageLoading"
                          ></v-progress-linear>
                        </v-card-text>
                        <v-row>
                          <v-col>
                            <v-sheet
                              style="overflow-y: auto; max-height: 600px; padding: 16px"
                              class="pa-4"
                              max-height="500px"
                              overflow-y="auto"
                              overflow-x="hidden"
                              rounded
                            >
                              <v-treeview
                                style="max-height: 100%; overflow-y: auto"
                                :items="directoryTree"
                                activatable
                                item-key="name"
                                open-on-click
                                hoverable
                                transition
                                @item-click="openPreview"
                              >
                                <template v-slot:prepend="{ item }">
                                  <v-icon v-if="item.type === 'file'" small> fas fa-file </v-icon>
                                  <v-icon v-else small> fas fa-folder </v-icon>
                                </template>
                                <template v-slot:label="{ item }">
                                  <v-row @click="openPreview(item)">
                                    <v-col cols="12" md="10" class="text-left">
                                      <span>{{ item.name }}</span>
                                    </v-col>
                                    <v-col cols="12" md="2" class="text-right"
                                      ><v-btn v-if="item.type === 'file'" icon small @click="downloadFile(item.url)">
                                        <v-icon>mdi-download</v-icon>
                                      </v-btn></v-col
                                    >
                                  </v-row>
                                </template>
                              </v-treeview>
                            </v-sheet>
                          </v-col>
                          <v-col>
                            <v-card-text v-if="previewedUrl !== ''">
                              <div style="max-width: 100%; overflow: hidden">
                                <iframe :src="previewedUrl" frameborder="0" style="width: 100%; height: 600px"></iframe>
                              </div>
                            </v-card-text>
                          </v-col>
                        </v-row>

                        <v-spacer></v-spacer>
                        <v-card-actions class="h-full">
                          <v-spacer></v-spacer>

                          <v-btn color="primary" text @click="fetchStorage" :loading="dataStorageLoading"
                            >Raffraichir</v-btn
                          >
                        </v-card-actions>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-spacer></v-spacer>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="app-content-container pa-5">
        <!-- <v-alert v-if="alerts.length > 0" text prominent dense border="top" :type="alerts[0].state">
          <marquee behavior="" direction="">
            <strong>{{ messages }}</strong></marquee
          >
        </v-alert> -->
        <div v-if="alerts.length > 0" v-for="(alert, index) in alerts" v-bind:key="index">
          <v-alert v-if="alert.majorUpdate === 0" text prominent dense border="top" :type="alert.state">
            <marquee behavior="" direction="">
              <strong>{{ alert.datetime_display }} {{ alert.message }}</strong></marquee
            >
          </v-alert>
        </div>
        <slot></slot>
      </div>
      <vertical-nav-menu
        :count="count"
        @notification-opened="handleNotificationOpen"
        :isDrawerOpen="isDrawerOpen"
      ></vertical-nav-menu>

      <vertical-nav-notification
        v-if="loggedIn"
        @count="handleCount"
        :isOpen="notificationOpened"
      ></vertical-nav-notification>
      <v-speed-dial
        style="z-index: 9999"
        v-if="loggedIn && isSuperAdmin"
        ref="speedDial"
        fixed
        bottom
        right
        @mousedown.native.prevent="dragStart"
        @touchstart.native.prevent="dragStart"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="EoleBlue" dark fab large>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-tools </v-icon>
          </v-btn>
        </template>
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              target="_blank"
              v-on="on"
              v-bind="attrs"
              attr
              small
              fab
              dark
              color="EoleBlueLighten"
              @click="openDialogStorage"
            >
              <i class="fa-solid fa-server EoleBlue--text"></i>
            </v-btn>
          </template>

          <span>Voir la consommation de données sur le serveur</span>
        </v-tooltip> -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              target="_blank"
              v-on="on"
              v-bind="attrs"
              attr
              fab
              dark
              color="EoleBlueLighten"
              @click="runPilotingDialog"
            >
              <i class="fa-solid fa-toolbox EoleBlue--text"></i>
            </v-btn>
          </template>

          <span>Tableau de pilotage Mes Services Assurance</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              href="https://status.anavel.bzh/status/anavel"
              target="_blank"
              v-on="on"
              v-bind="attrs"
              attr
              fab
              dark
              color="EoleBlueLighten"
            >
              <i class="fa-solid fa-wifi EoleBlue--text"></i
            ></v-btn>
          </template>
          <span>Voir les services opérationnels</span>
        </v-tooltip>
      </v-speed-dial>
    </v-main>
    <v-footer v-if="!$vuetify.breakpoint.xs" padless color="transparent" class="footer-padding my-3">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <v-row>
            <v-col cols="12" md="6" align-self="center">
              {{ appName }} {{ version }} &copy; {{ new Date().getFullYear() }}
              <a href="https://anavel.bzh" class="text-decoration-none" target="_blank"
                >Un service proposé par Anavel</a
              >
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-btn v-for="link in links" :key="link.text" text rounded :href="link.href" target="_blank">
                {{ link.text }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import vueTopprogress from "vue-top-progress"
import Vue from "vue"
import moment from "moment"
moment.locale("fr")
import config from "@/views/config/config"

Vue.use(vueTopprogress)
import { ref } from "@vue/composition-api"
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js"
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue"
import VerticalNavNotification from "./components/vertical-nav-menu/VerticalNavNotification.vue"

import ThemeSwitcher from "./components/ThemeSwitcher.vue"
import AppBarUserMenu from "./components/AppBarUserMenu.vue"
import Shortcuts from "./components/Shortcuts.vue"
import { socket } from "@/utils/socket"
import DialogTutorial from "./components/Tutorial.vue"

import { getCookie } from "@/utils/cookies"

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Shortcuts,
    VerticalNavNotification,
    DialogTutorial,
  },
  data: () => ({
    isTutorial: false,
    dialogMajorUpdate: false,
    directoryTree: [],
    dialogStorage: false,
    diskStorage: {},
    right: 20, // Position de départ - droite
    bottom: 20, // Position de départ - bas
    start: { x: 0, y: 0 },
    newAlert: {
      state: "success",
      message: "",
      majorUpdate: false,
    },
    dialogAlert: false,
    loggedIn: true,
    hover: false,
    direction: "top",
    fab: false,

    transition: "slide-y-reverse-transition",
    notificationOpened: false,
    openNotification: false,
    drawer: null,
    links: [
      {
        text: "Mentions légales",
        href: "/legal/mentions",
        target: "_blank",
      },
      {
        text: "Charte de confidentialité",
        href: "/legal/privacy",
        target: "_blank",
      },
    ],
    items: [
      { title: "Home", icon: "mdi-view-dashboard" },
      { title: "About", icon: "mdi-forum" },
    ],
    date: null,
    version: config.informations.version,
    appName: config.applicationName,
    show: false,
    loading: false,
    items: [],
    search: null,
    select: null,
    drawerOpen: true,
    count: 0,
    alerts: [],
    messages: "",
    isSuperAdmin: false,
    dataStorageLoading: false,
    previewedUrl: "",
    dialogPiloting: false,
    tabsPiloting: "tab-1",
    pappers: {
      tokensRemaining: 0,
      tokensSubscribed: 0,
      percent: 0,
    },
    agencies: [],
  }),
  created() {
    localStorage.getItem("tutorial") == 1 ? (this.isTutorial = true) : (this.isTutorial = false)
    this.$store.state.userRole === "superadministrateur" ? (this.isSuperAdmin = true) : (this.isSuperAdmin = false)
    this.date = moment().format("dddd Do MMMM YYYY")
    this.fetchAlerts()
  },
  mounted() {
    this.$refs.topProgress.start()
    this.$nextTick(() => {
      this.$refs.topProgress.done()
    })
    socket.on("newAlert", () => {
      this.fetchAlerts()
    })
  },

  methods: {
    async getMSAAgencies() {
      this.agencies = []
      try {
        const response = await fetch(`${config.apiUri}/admin/agencies`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()
        this.agencies = data
      } catch (e) {
        console.log(e)
      }
    },
    runPilotingDialog() {
      this.dialogPiloting = true
      this.fetchStorage()
      this.getTokens()
      this.getMSAAgencies()
    },
    colorPercentage(percentage) {
      if (percentage < 50) {
        return "success"
      } else if (percentage < 80) {
        return "warning"
      } else {
        return "error"
      }
    },
    inverseColorPercentage(percentage) {
      if (percentage > 50) {
        return "success" // Au-dessus de 50% devient "vert"
      } else if (percentage >= 10) {
        return "warning" // Entre 10% et 50% devient "jaune"
      } else {
        return "error" // Moins de 10% devient "rouge"
      }
    },
    calculatePercentage(used, total) {
      return Math.round((used / total) * 100)
    },
    async getTokens() {
      this.pappers = {}
      try {
        const response = await fetch(`${config.apiUri}/pappers/informations/get-tokens`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()
        this.pappers = data
        this.pappers.percent = this.calculatePercentage(this.pappers.tokensRemaining, this.pappers.tokensSubscribed)
      } catch (e) {
        console.log(e)
      }
    },
    verifyIfMajorUpdate() {
      this.alerts.forEach(alert => {
        if (alert.majorUpdate === 1) {
          console.log("vrif", alert)
          this.dialogMajorUpdate = true
        }
      })
    },
    async previewFile(path) {
      const idAccount = this.$store.state.user.id
      let url
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })
        const data = await response.blob()
        url = URL.createObjectURL(data)
      } catch (error) {
        console.error(error)
      }

      return url
    },
    async openPreview(item) {
      console.log(item)
      if (item.type !== "file") return // Ne rien faire si c'est un dossier

      this.previewedUrl = await this.previewFile(item.url) // Récupère l'URL du fichier à prévisualiser
    },
    async downloadFile(url) {
      const idAccount = this.$store.state.user.id
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({ url: url }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.blob() // Récupère le blob du fichier
        const downloadUrl = URL.createObjectURL(data) // Crée une URL pour le blob

        // Créer un élément <a> pour déclencher le téléchargement
        const a = document.createElement("a")
        a.href = downloadUrl
        a.download = url.split("/").pop() // Utilise le nom du fichier dans l'URL ou définissez un nom spécifique
        document.body.appendChild(a)
        a.click()

        // Nettoyage: retire l'élément <a> et libère l'URL objet
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error("Error downloading the file:", error)
      }
    },
    convertToVuetifyTreeFormat(tree) {
      const result = []

      function traverse(node, result) {
        Object.keys(node).forEach(key => {
          const value = node[key]
          const item = { name: key }

          if (value.type === "directory") {
            item.children = []
            traverse(value.children, item.children)
          } else {
            item.path = value.path // ajout du chemin pour le téléchargement
            item.type = "file"
            item.url = value.url
          }

          result.push(item)
        })
      }

      traverse(tree, result)
      return result
    },
    async openDialogStorage() {
      await this.fetchStorage()
      this.dialogStorage = true
    },
    async fetchStorage() {
      this.diskStorage = {}
      this.directoryTree = []
      this.dataStorageLoading = true
      try {
        const response = await fetch(`${config.apiUri}/storage`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).finally(() => {
          this.dataStorageLoading = false
        })

        const data = await response.json()
        this.diskStorage = data.diskSpace
        this.directoryTree = this.convertToVuetifyTreeFormat(data.filesTree)
      } catch (e) {
        console.error("Failed to fetch storage info:", e)
      }
    },
    dragStart(event) {
      const speedDial = this.$refs.speedDial.$el

      // Determine starting position
      let startX = event.type.includes("touch") ? event.touches[0].clientX : event.clientX
      let startY = event.type.includes("touch") ? event.touches[0].clientY : event.clientY
      let startRight = window.innerWidth - speedDial.offsetLeft - speedDial.offsetWidth
      let startBottom = window.innerHeight - speedDial.offsetTop - speedDial.offsetHeight

      const move = moveEvent => {
        let clientX = moveEvent.type.includes("touch") ? moveEvent.touches[0].clientX : moveEvent.clientX
        let clientY = moveEvent.type.includes("touch") ? moveEvent.touches[0].clientY : moveEvent.clientY

        let newRight = startRight - (clientX - startX)
        let newBottom = startBottom - (clientY - startY)

        speedDial.style.right = `${newRight}px`
        speedDial.style.bottom = `${newBottom}px`
      }

      const upListener = () => {
        document.removeEventListener("mousemove", move)
        document.removeEventListener("touchmove", move)
        document.removeEventListener("mouseup", upListener)
        document.removeEventListener("touchend", upListener)
      }

      document.addEventListener("mousemove", move)
      document.addEventListener("touchmove", move)
      document.addEventListener("mouseup", upListener)
      document.addEventListener("touchend", upListener)
    },
    async deleteAlert(id) {
      try {
        await fetch(`${config.apiUri}/alerts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.$toast.success("L'alerte a bien été supprimée")
            socket.emit("newAlert")

            window.location.reload()
          } else {
            this.$toast.error("Une erreur est survenue lors de la suppression de l'alerte")
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async postAlert() {
      const postData = {
        state: this.newAlert.state,
        message: this.newAlert.message,
        majorUpdate: this.newAlert.majorUpdate,
      }
      try {
        const response = await fetch(`${config.apiUri}/alerts`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },

          body: JSON.stringify(postData),
        })

        this.dialogAlert = false

        this.$toast.success("L'alerte a bien été ajoutée")

        socket.emit("newAlert")

        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchAlerts() {
      this.dialogMajorUpdate = false
      this.alerts = []
      this.messages = ""

      try {
        const response = await fetch(`${config.apiUri}/alerts`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        data.forEach((alert, index) => {
          this.alerts.push({
            id: alert.id,
            state: alert.state,
            message: alert.message,
            datetime: alert.date_posted,
            datetime_display: moment(alert.date_posted).format("[(]DD/MM/YYYY [à] HH:mm[)]"),
            majorUpdate: alert.major,
          })

          //order by date
          this.alerts.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime)
          })

          // this.messages += `${moment(alert.date_posted).format("DD/MM/YYYY HH:mm")} ${alert.message} ${
          //   index !== data.length - 1 ? " | " : ""
          // }`
        })
      } catch (e) {
        console.log(e)
      }

      this.verifyIfMajorUpdate()
    },
    handleNotificationOpen(value) {
      this.notificationOpened = value // Met à jour la variable avec la valeur reçue de VerticalNavMenu
    },
    handleCount(value) {
      this.count = value // Met à jour la variable avec la valeur reçue de VerticalNavMenu
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    select(newSelectedArray) {
      switch (newSelectedArray) {
        case "Tableau de bord":
          this.$router.push("/dashboard").catch(() => {})
          break
        case "Générateur de pdf":
          this.$router.push("/pdf/generate").catch(() => {})
          break
        case "Configuration des pdf":
          this.$router.push("/pdf/configuration").catch(() => {})
          break
        case "Historique des pdf":
          this.$router.push("/pdf/history").catch(() => {})
          break
        case "Boutique":
          this.$router.push("/store").catch(() => {})
          break
        case "Gestion des comptes":
          this.$router.push("/mainaccount/settings").catch(() => {})
          break
        case "Mon compte":
          this.$router.push("/account/setting").catch(() => {})
          break
        default:
          this.$router.push("/dashboard").catch(() => {})
          break
      }
      this.loading = false
    },
  },

  setup() {
    const isDrawerOpen = ref(null)
    let searchText = ref("")
    const list = [
      "Tableau de bord",
      "Générateur de pdf",
      "Configuration des pdf",
      "Historique des pdf",
      "Boutique",
      "Gestion des comptes",
      "Mon compte",
    ]

    function filteredList() {
      return list.filter(data => data.toLowerCase().includes(searchText.value.toLowerCase()))
    }

    return {
      isDrawerOpen,
      searchText,
      filteredList,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.drawer-two {
  margin: 0px 0px 0px 75px !important;
  // position: absolute !important;
}
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.app-content-container {
  padding: 10px 0px 0px 150px !important;
  height: 100%;
}

.main-padding {
  padding: 0px 100px 60px 0px !important;
}

@media screen and (min-width: 2000px) {
  .app-content-container {
    padding: 10px 0px 0px 150px !important;
    max-width: 1700px !important;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .app-content-container {
    padding: 10px 0px 0px 20px !important;
    margin: auto;
  }

  .main-padding {
    padding: 0px 20px 20px 0px !important;
  }

  .footer-padding {
    padding: 0px 0px 0px !important;
  }
}

.boxed-container {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

.app-padding {
  padding: 0px 0px 0px 0px !important;
}

.drawer-padding {
  padding: 200px 0px 0px 0px !important;
}

.footer-padding {
  padding: 0px 0px 0px 70px !important;
}

.scrolling-text {
  overflow: hidden;
  width: 100%;
}

.text-container {
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.text-content {
  font-size: 18px;
  padding-left: 100%; /* La largeur du conteneur pour forcer le défilement */
}

.scrolling-texte {
  white-space: nowrap;
  overflow: hidden;
  animation: scrolling 30s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.v-alert {
  overflow: hidden;
}
</style>
