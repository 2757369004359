import * as dotenv from "dotenv"
dotenv.config()

var config = {
  applicationName: "Mes Services Assurance",
  informations: {
    //! Choose between 'production' or 'dev'
    environment: "safety",
    version: "2.4.8",
  },
  authors: {
    owner: "Anavel",
    developper: [
      {
        name: "FRICHOT ROMAN",
        job: "Developper Manager",
        contact: "roman.frichot@anavel.bzh",
      },
    ],
    gdprContact: "david.audren@anavel.bzh",
    email: "contact@anavel.bzh",
  },
  apiUri: null,
  ged: null,
  tokenPappers: "90dca6804ce3b81abac691384ae4ae505fe66a709551e245",
}

export default config
