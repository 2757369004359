<template>
  <div class="parent">
    <!-- <h2 class="text-above-loader">Bienvenue {{ collaborator }}</h2> -->
    <div class="loader-container">
      <div class="spinner"></div>
      <!-- <img class="centered-image" width="150px" src="https://anavel.bzh/assets/logo_anavel.png" /> -->
      <img class="centered-image" width="150px" src="./msa.svg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collaborator: localStorage.getItem("firstname"),
    }
  },
  setup() {
    return {}
  },
}
</script>

<style>
.text-above-loader {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader-container {
  position: relative;
  z-index: 0;
}
.parent {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 90%);
}

/* take full page */
.pulse {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fcc03c;
  box-shadow: 0 0 0 0 rgba(252, 192, 60, 0.5);
  animation: pulse-kefe91md 1.5s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pulse:before,
.pulse:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  box-shadow: 0 0 0 0 rgba(252, 192, 60, 0.5);
  animation: inherit;
  animation-delay: -0.5s;
}

.pulse:after {
  animation-delay: -1s;
}

@keyframes pulse-kefe91md {
  100% {
    box-shadow: 0 0 0 44.8px #0000;
  }
}

.spinner {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fcc03c 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, #fcc03c);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}
.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
  animation: scale-effect 3s infinite alternate;
}

@keyframes scale-effect {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
