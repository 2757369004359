<template>
  <v-navigation-drawer v-if="!$vuetify.breakpoint.xs" permanent color="primary" app class="app-navigation-menu">
    <div
      class="btn-group"
      v-if="
        (!!user && item.admin && isAdmin) ||
        (item.superadmin && isSuperAdmin) ||
        (item.collaborator && isCollaborator) ||
        (item.guest && isGuest) ||
        (item.manager && isManager) ||
        (item.social && isSocial)
      "
      v-for="(item, index) in items"
      :key="index"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-badge v-if="item.badgePresent" content="6" color="EoleError" overlap offset-x="20" offset-y="20">
            <v-btn
              style="box-shadow: none !important"
              fab
              class="btn-menu white--text transparent"
              :active-class="'bg-gradient-primary EoleYellow--text'"
              :to="{ name: item.to }"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :class="{ 'alternate-icon-small': !item.icon }">
                {{ item.icon || item.alternateIcon }}
              </v-icon>
            </v-btn>
          </v-badge>
          <v-btn
            v-else
            style="box-shadow: none !important"
            fab
            class="btn-menu white--text transparent"
            :active-class="'bg-gradient-primary EoleYellow--text'"
            :to="{ name: item.to }"
            small
            v-bind="attrs"
            v-on="on"
          >
            <i
              :class="item.class"
              @mouseover="hoverHandler($event, item.animation)"
              @mouseleave="stopSpin($event, item.animation)"
            ></i>
          </v-btn>
        </template>
        <span>{{ item.title }} </span>
      </v-tooltip>
    </div>
    <div class="btn-group">
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-x width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip right>
            <template v-slot:activator="{ odn, atdtrs }">
              <v-btn
                style="box-shadow: none !important"
                fab
                class="btn-menu white--text transparent"
                :active-class="'bg-gradient-primary EoleYellow--text'"
                small
                v-bind="attrs"
                v-on="on"
              >
                <i class="fa-solid fa-plus sizeIcon"></i>
              </v-btn>
            </template>
            <span>Ajouter un raccourcis </span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item-group multiple>
            <v-list-item
              v-if="
                (!shortcut.disable && shortcut.admin && isAdmin) ||
                (shortcut.superadmin && isSuperAdmin) ||
                (shortcut.collaborator && isCollaborator) ||
                (shortcut.guest && isGuest) ||
                (shortcut.manager && isManager)
              "
              v-for="shortcut in shortsProposed"
              v-bind:key="shortcut.id"
              @click="shortcutChecked(shortcut)"
            >
              <template v-slot:default>
                <v-list-item-action>
                  <v-checkbox v-bind:input-value="shortcut.use" color="EoleBlue"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ shortcut.title }} </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <i :class="['fa-solid fa-star', shortcut.use ? 'EoleYellow--text' : '']"></i>
                </v-list-item-icon>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <v-spacer></v-spacer>

    <div class="app-bar-user-menu">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="isAdmin || isManager || isSuperAdmin"
            id="btn-manage"
            style="box-shadow: none !important"
            fab
            class="btn-menu white--text transparent ml-1 pa-5"
            :active-class="'bg-gradient-primary EoleYellow--text'"
            small
            v-bind="attrs"
            v-on="on"
            :to="{ name: 'settingsOffice' }"
          >
            <i
              class="fa-solid fa-gear fa-2x"
              @mouseover="hoverHandler($event, 'spin')"
              @mouseleave="stopSpin($event, 'spin')"
            ></i>
          </v-btn>
        </template>
        <span>Mon cabinet {{ agency }} </span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :value="count"
            :content="count"
            v-if="isAdmin || isCollaborator || isManager || isSuperAdmin"
            color="EoleError"
            overlap
            offset-x="17"
            offset-y="15"
          >
            <v-btn
              @click="emitOpen"
              style="box-shadow: none !important"
              fab
              class="white--text transparent mb-5 pa-5"
              x-large
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              @mouseover="hoverHandler($event, 'shake')"
              @mouseleave="stopSpin($event, 'shake')"
            >
              <i v-if="count > 0" class="fa-solid fa-bell sizeIcon"></i>
              <i v-else class="fa-regular fa-bell sizeIcon"></i>
            </v-btn>
          </v-badge>
        </template>
        <span>Mes notifications ({{ count }}) </span>
      </v-tooltip>

      <app-bar-user-menu></app-bar-user-menu>
    </div>
  </v-navigation-drawer>
  <CurvedBottomNavigation
    v-else
    :options="options"
    v-model="selected"
    foreground-color="#FCC03C"
    badge-color="#C1272D"
    background-color="#001F47"
    icon-color="white"
  >
  </CurvedBottomNavigation>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import "@mdi/font/css/materialdesignicons.css" // Load icons
import AppBarUserMenu from "../AppBarUserMenu.vue"
import { CurvedBottomNavigation } from "bottom-navigation-vue"

import moment from "moment"
moment.locale("fr")

export default {
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  data: () => ({
    notificationOpen: false,
    options: [
      {
        id: 1,
        icon: "fas fa-home",
        title: "Accueil",
        path: "/dashboard",
      },
      // { id: 2, icon: "fas fa-wallet", title: "Mes charges", path: "/services/charges" },
      {
        id: 3,
        icon: "fas fa-plus",
        title: "Modules",
        childs: [
          { id: 302, icon: "fas fa-file-contract", title: "Devoir de conseil" },
          { id: 303, icon: "fas fa-file-signature", title: "Mandat d'étude" },
          { id: 304, icon: "fas fa-heart", title: "Mes favoris" },
          { id: 305, icon: "fas fa-wallet", title: "Mes charges" },
          { id: 305, icon: "fas fa-piggy-bank", title: "Mes Commissions" },
        ],
      },
      { id: 5, icon: "fas fa-user", title: "Mon compte", path: "/pages/account-settings" },
      // { id: 4, icon: "fas fa-bell", title: "Notification", badge: 15 },
      {
        id: 6,
        icon: "fas fa-cog",
        title: "Administration",
        childs: [
          { id: 301, icon: "fas fa-building", path: "/settings/office" },
          { id: 302, icon: "fas fa-users", path: "/settings/office" },
          { id: 303, icon: "fas fa-file", path: "/settings/office" },
          { id: 304, icon: "fas fa-building-arrow", path: "/settings/office" },
        ],
      },
      {
        id: 7,
        icon: "fas fa-right-from-bracket",
        title: "Déconnexion",
        path: "/logout",
      },
    ],
    selected: 1,
    menu: false,
    issuperadmin: false,
    isAdmin: false,
    isCollaborator: false,
    isGuest: false,
    isManager: false,
    isSocial: false,
    shortsProposed: [
      // {
      //   id: 1,
      //   content: "Lockself",
      //   path: "https://api.lockself.com/application/index.html#/login",
      //   description:
      //     "Vous souhaitez générer un mot de passe de manière sécurisé ou gérer vos mots de passe enregistrés ? Cliquez-ici",
      //   imgName: "lockself",
      //   disable: false,
      //   target: false,
      //   otherSite: true,
      //   admin: false,
      // },
      {
        title: "Mes Clients",
        to: "client",
        class: "fa-solid fa-users-viewfinder fa-2x",
        animation: "",
        use: false,
        superadmin: true,
        admin: false,
        collaborator: false,
        guest: false,
        manager: false,
      },
      {
        title: "Devoir de conseil",
        to: "serviceLddc",
        class: "fa-solid fa-file-contract fa-2x",
        animation: "",
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
        manager: true,
      },
      {
        title: "Mandat d'étude",
        to: "serviceMandatEtude",
        class: "fa-solid fa-file-signature fa-2x",
        animation: "",
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Mandat de résiliation",
        to: "store",
        class: "fa-solid fa-file-circle-minus fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Parrainage",
        to: "serviceAdminSponsor",
        class: "fa-solid fa-handshake fa-2x",
        animation: "",
        disable: false,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: true,
      },
      {
        title: "Signature électronique",
        to: "store",
        class: "fa-solid fa-signature fa-2x",
        animation: "",
        disable: true,

        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Découverte client",
        to: "store",
        class: "fa-solid fa-triangle-exclamation fa-2x",
        animation: "",
        disable: true,

        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Mes favoris",
        to: "serviceExtranet",
        class: "fa-solid fa-heart fa-2x",
        animation: "",
        disable: false,

        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Suivi congé",
        to: "store",
        class: "fa-solid fa-plane-departure fa-2x",
        animation: "",
        disable: true,

        use: false,
        superadmin: true,
        admin: true,
        collaborator: false,
        guest: false,
      },
      {
        title: "Classeur ACPR",
        to: "store",
        class: "fa-solid fa-person-military-pointing fa-2x",
        animation: "",
        disable: true,

        use: false,
        superadmin: true,
        admin: true,
        collaborator: false,
        guest: false,
      },
      {
        title: "Mes charges",
        to: "serviceSuppliers",
        class: "fa-solid fa-wallet fa-2x",
        animation: "",
        disable: false,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: false,
        guest: false,
      },
      // {
      //   title: "Mes commissions",
      //   to: "serviceCommissions",
      //   class: "fa-solid fa-piggy-bank fa-2x",
      //   animation: "",
      //   disable: false,
      //   use: false,
      //   admin: false,
      //   collaborator: false,
      //   guest: false,      },
      {
        title: "Rappel de client",
        to: "contact",
        class: "fa-solid fa-phone-volume fa-2x",
        animation: "",
        disable: false,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Mes collègues",
        to: "colleague",
        class: "fa-solid fa-people-group fa-2x",
        animation: "",
        disable: false,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Mes inspecteurs",
        to: "store",
        class: "fa-solid fa-user-tie fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Ma compta",
        to: "store",
        class: "fa-solid fa-euro-sign fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: false,
        guest: false,
      },
      {
        title: "Déclarer un sinistre",
        to: "client",
        class: "fa-solid fa-car-burst fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Flotte auto",
        to: "client",
        class: "fa-solid fa-truck-field fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Bulletin social individuel",
        to: "client",
        class: "fa-solid fa-file-contract fa-2x",
        animation: "",
        disable: true,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
      {
        title: "Mon espace entreprise",
        to: "serviceTable",
        class: "fa-solid fa-house-chimney-user fa-2x",
        animation: "",
        disable: false,
        use: false,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: false,
      },
    ],

    agency: null,
    items: [
      {
        title: "Mes services",
        to: "dashboard",
        class: "fa-solid fa-home fa-2x",
        animation: "",
        use: true,
        superadmin: true,
        admin: true,
        collaborator: true,
        guest: true,
        manager: true,
      },
      {
        title: "Gestion du cabinet",
        to: "Office",
        class: "fa-solid fa-home fa-2x",
        animation: "",
        use: true,
        superadmin: false,
        admin: false,
        collaborator: false,
        guest: false,
        manager: false,
        social: true,
      },
      // {
      //   title: "Lettre de devoir de conseil",
      //   to: "serviceLddc",
      //   class: "fa-solid fa-file-contract fa-2x",
      //   animation: "flip",
      //   use: true,
      // },
      // {
      //   title: "Demandes de rappel",
      //   to: "contact",
      //   class: "fa-solid fa-phone-volume fa-2x",
      //   animation: "shake",
      //   use: true,
      // },
      // {
      //   title: "Gestionnaire de charges",
      //   to: "serviceSuppliers",
      //   class: "fa-solid fa-wallet fa-2x",
      //   animation: "beat",
      //   use: true,
      // },
      // {
      //   title: "Portefeuille client",
      //   to: "client",
      //   class: "fa-solid fa-users-viewfinder fa-2x",
      //   animation: "",
      //   use: true,
      // },
    ],
    grade: null,
  }),
  components: {
    AppBarUserMenu,
    CurvedBottomNavigation,
  },
  props: ["count"],
  methods: {
    setManage() {
      console.log("setManage")
      // this.$router.push({ name: "settingsOffice", params: { id: 1 } })
    },
    emitOpen() {
      // emit to the parent component
      this.notificationOpen = !this.notificationOpen
      this.$emit("notification-opened", this.notificationOpen)
    },
    shortcutChecked(shortcutChoose) {
      if (shortcutChoose.use) {
        shortcutChoose.use = false

        //remove shortcut in array items
        this.items.splice(this.items.indexOf(shortcutChoose), 1)
        localStorage.setItem("shortcuts", JSON.stringify(this.items))
        // put shortcutUse to false
      } else {
        shortcutChoose.use = true
        this.items.push(shortcutChoose)
        localStorage.setItem("shortcuts", JSON.stringify(this.items))
      }
    },
    verifyShortcut(shortcut) {
      if (this.items.includes(shortcut)) {
        return true
      } else {
        return false
      }
    },
    hoverHandler(event, animation) {
      event.currentTarget.classList.add(`fa-${animation}`)
    },
    stopSpin(event, animation) {
      event.currentTarget.classList.remove(`fa-${animation}`)
    },
  },
  created() {
    this.$store.state.userRole === "superadministrateur" ? (this.isSuperAdmin = true) : (this.isSuperAdmin = false)
    this.$store.state.userRole === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)
    this.$store.state.userRole === "collaborateur" ? (this.isCollaborator = true) : (this.isCollaborator = false)
    this.$store.state.userRole === "manager" ? (this.isManager = true) : (this.isManager = false)
    this.$store.state.userRole === "invite" ? (this.isGuest = true) : (this.isGuest = false)
    this.$store.state.userRole === "cabinet social" || this.$store.state.userRole === "manager due"
      ? (this.isSocial = true)
      : (this.isSocial = false)

    if (this.isAdmin || this.isManager || this.isCollaborator || this.isSocial) {
      this.agency = this.$store.state.user.agency_name
    }

    this.items = localStorage.getItem("shortcuts") ? JSON.parse(localStorage.getItem("shortcuts")) : this.items

    // check in items if use is true if yes add use true to shortcut
    this.items.forEach(item => {
      this.shortsProposed.forEach(shortcut => {
        if (item.title === shortcut.title) {
          shortcut.use = true
        }
      })
    })
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
// * {
//   overflow: hidden;
// }
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.btn-menu {
  cursor: pointer;
  height: 2.5rem !important;
  width: 2.5rem !important;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.btn-group {
  padding: 1rem !important;
  color: #fff !important;
  z-index: 9999 !important;
  @include elevationTransition();
}

.app-navigation-menu {
  width: 4.5rem !important;
  height: 100vh !important;
  z-index: 2 !important;
}

.app-bar-user-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0.75rem;
  margin-bottom: 2rem;
}

.sizeIcon {
  font-size: 1.5rem !important;
}
</style>
