<template>
  <v-fade-transition mode="out-in">
    <v-menu left v-model="menu" :close-on-content-click="false" nudge-bottom="40" max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large fab v-bind="attrs" v-on="on"
          ><v-icon>{{ icons.mdiViewGridPlusOutline }}</v-icon></v-btn
        >
      </template>

      <v-card class="" tile>
        <v-card-title>Mes raccourcis</v-card-title>
        <v-row class="ma-0 pa-0">
          <v-col
            v-for="short in shortcuts"
            v-bind:key="short.id"
            cols="6"
            class="text-center border-t border-e d-flex justify-center ma-0 pa-0"
            ><v-card tile flat link max-height="200px" width="400px" :href="short.link">
              <v-card-text>
                <v-avatar color="#F1F1F2">
                  <v-icon color="#838188"> {{ short.icon }}</v-icon>
                </v-avatar>
              </v-card-text>

              <v-card-text class="text-center"> {{ short.name }} </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-card>
    </v-menu>
  </v-fade-transition>
</template>

<script>
import { mdiViewGridPlusOutline } from "@mdi/js"

export default {
  data: () => ({
    shortcuts: [
      {
        id: 0,
        name: "Mes services",
        description: "Retour à l'accueil",
        icon: "mdi-view-dashboard",
        link: "/dashboard",
      },
      {
        id: 1,
        name: "Mes demandes de rappel",
        description: "Consulter vos demandes de rappels en attente",
        icon: "mdi-phone",
        link: "/contact",
      },
      {
        id: 2,
        name: "Mes collègues",
        description: "Consulter le profil de vos collègues",

        icon: "mdi-account-multiple",
        link: "/colleague",
      },
      {
        id: 3,
        name: "Mon cabinet",
        description: "Consulter les informations de votre cabinet",

        icon: "mdi-office-building",
        link: "#",
      },
      {
        id: 4,
        name: "Générateur de documents",
        description: "Générer les lettres de devoir de conseil, de mandat d'études etc...",
        icon: "mdi-office-building",
        link: "#",
      },

      {
        id: 6,
        name: "FAQ/Support",
        description: "Besoin d'aide ou d'un renseoignement ? Contactez-nous !",
        icon: "mdi-office-building",
        link: "#",
      },
    ],
  }),
  setup() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      icons: {
        mdiViewGridPlusOutline,
      },
    }
  },
}
</script>

<style>
.border-shortcuts {
  border: 1px solid #e7e6e8 !important;
}
.no-padding {
  padding: 0 !important;
}

.border-e {
  border-inline-end-width: thin !important;
  border-inline-end-style: solid !important;
  border-inline-end-color: #e7e6e8 !important;
}
.border-t {
  border-block-start-width: thin !important;
  border-block-start-style: solid !important;
  border-block-start-color: #e7e6e8 !important;
}
</style>
