import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'
import '@fortawesome/fontawesome-free/css/all.css'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import PrimeVue from 'primevue/config'
import {
  getCookie
} from './utils/cookies'


Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(Element)
Vue.use(PrimeVue)

const token = getCookie("jwt")
if (token) {
  store.commit('setAuthState', {
    isAuthenticated: true,
    token
  });
  if (store.state.userRole !== "invite" && store.state.userRole !== undefined) {
    store.commit('setAuthState', {
      isAuthenticated: true,
      token
    });
    store.dispatch('fetchUser').then(() => {
      new Vue({
        router,
        store,
        vuetify,
        Element,
        PrimeVue,
        render: h => h(App),
      }).$mount('#app')
    })
  } else {
    store.commit('setAuthStateGuest', {
      isAuthenticated: true,
      token
    });
    console.log("invite")
    store.dispatch('fetchGuest').then(() => {
      console.log(store.state)
      new Vue({
        router,
        store,
        vuetify,
        Element,
        PrimeVue,
        render: h => h(App),
      }).$mount('#app')
    })
  }
} else {
  new Vue({
    router,
    store,
    vuetify,
    Element,
    PrimeVue,
    render: h => h(App),
  }).$mount('#app')
}
