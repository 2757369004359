require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#001F47', // Couleur principale actuelle
        primaryLight: '#003366', // Variation plus claire de la couleur principale
        primaryDark: '#000f24', // Variation plus foncée de la couleur principale

        accent: '#0d6efd', // Accent actuelle
        accentLight: '#5b9bff', // Variation plus claire de l'accent
        accentDark: '#0044b4', // Variation plus foncée de l'accent

        secondary: '#8A8D93', // Couleur secondaire actuelle
        secondaryLight: '#b1b3b8', // Variation plus claire de la couleur secondaire
        secondaryDark: '#616367', // Variation plus foncée de la couleur secondaire

        success: '#28a745', // Couleur de succès actuelle
        successLight: '#5ec774', // Variation plus claire de la couleur de succès
        successDark: '#1c7130', // Variation plus foncée de la couleur de succès

        info: '#001F47', // Couleur d'info actuelle
        infoLight: '#33496a', // Variation plus claire de la couleur d'info
        infoDark: '#001226', // Variation plus foncée de la couleur d'info

        warning: '#FCC03C', // Couleur d'avertissement actuelle
        warningLight: '#ffdc7a', // Variation plus claire de la couleur d'avertissement
        warningDark: '#c6912c', // Variation plus foncée de la couleur d'avertissement

        error: '#FF4C51', // Couleur d'erreur actuelle
        errorLight: '#ff7c80', // Variation plus claire de la couleur d'erreur
        errorDark: '#b5363a', // Variation plus foncée de la couleur d'erreur

        EoleBlue: "#001F47", // Couleur EoleBlue actuelle
        EoleYellow: "#FCC03C", // Couleur EoleYellow actuelle
        EoleGray: "#9D9D9C", // Couleur EoleGray actuelle
        GrayText: "#8F8F8F", // Couleur GrayText actuelle
        EoleError: "#C1272D", // Couleur EoleError actuelle
        EoleGreen: "#52b788", // Couleur EoleGreen actuelle
        EoleBlueLighten: "#e1e5e9", // Couleur EoleBlueLighten actuelle
        EoleErrorBase: "#ff4c51", // Couleur EoleErrorBase actuelle

        neutral: '#f5f5f5', // Couleur neutre supplémentaire
        neutralDark: '#e0e0e0', // Variation plus foncée de la couleur neutre
        neutralLight: '#fafafa', // Variation plus claire de la couleur neutre
      },
      dark: {
        primary: '#FCC03C', // Couleur principale actuelle
        primaryLight: '#ffd85e', // Variation plus claire de la couleur principale
        primaryDark: '#b48a2b', // Variation plus foncée de la couleur principale

        accent: '#0d6efd', // Accent actuelle
        accentLight: '#5b9bff', // Variation plus claire de l'accent
        accentDark: '#0044b4', // Variation plus foncée de l'accent

        secondary: '#8A8D93', // Couleur secondaire actuelle
        secondaryLight: '#b1b3b8', // Variation plus claire de la couleur secondaire
        secondaryDark: '#616367', // Variation plus foncée de la couleur secondaire

        success: '#28a745', // Couleur de succès actuelle
        successLight: '#5ec774', // Variation plus claire de la couleur de succès
        successDark: '#1c7130', // Variation plus foncée de la couleur de succès

        info: '#001F47', // Couleur d'info actuelle
        infoLight: '#33496a', // Variation plus claire de la couleur d'info
        infoDark: '#001226', // Variation plus foncée de la couleur d'info

        warning: '#FCC03C', // Couleur d'avertissement actuelle
        warningLight: '#ffdc7a', // Variation plus claire de la couleur d'avertissement
        warningDark: '#c6912c', // Variation plus foncée de la couleur d'avertissement

        error: '#FF4C51', // Couleur d'erreur actuelle
        errorLight: '#ff7c80', // Variation plus claire de la couleur d'erreur
        errorDark: '#b5363a', // Variation plus foncée de la couleur d'erreur

        EoleBlue: "#001F47", // Couleur EoleBlue actuelle
        EoleYellow: "#FCC03C", // Couleur EoleYellow actuelle
        EoleGray: "#9D9D9C", // Couleur EoleGray actuelle
        GrayText: "#8F8F8F", // Couleur GrayText actuelle
        EoleError: "#C1272D", // Couleur EoleError actuelle
        EoleGreen: "#52b788", // Couleur EoleGreen actuelle

        neutral: '#1c1c1c', // Couleur neutre supplémentaire
        neutralDark: '#333333', // Variation plus foncée de la couleur neutre
        neutralLight: '#282828', // Variation plus claire de la couleur neutre
      },
    },
  }

}
